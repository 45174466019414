.popup {
  position: fixed;
  top: 2em;
  left: 42.5%;
  background-color: #66c6b6;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  height: 3em;
  border-radius: 13px;
}
.message {
  color: white;
}
.popupButton{
  border: none;
  cursor: pointer;
  float: right;
  margin-left: 0.5em;
  margin-top: 0.5em;
  color: white;
  background-color: red;
  border-radius: 13px;
}