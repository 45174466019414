.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.header {
  font-size: 10em;
  font-weight: bold;
  color: #ccc;
}

.text {
  font-size: 2em;
  color: #aaa;
  margin: 1em 0;
}
.link {
  font-size: 1.5em;
  color: blue;
  text-decoration: underline;
}
