.loginInput {
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  border: 0px;
  border-radius: 13px;
  font-size: 16px;
  outline: none;
  height: 55px;
  width: 359px;
  background-color: rgba(47, 48, 46, 0.09);
  font-weight: bold;
}

.loginSubmitButton {
  margin-top: 1em;
  font-size: 16px;
  font-weight: 500;
  border-radius: 13px;
  border: 0px;
  width: 186px;
  height: 36px;
  color: white;
  background-color: #66c6b6;
  cursor: pointer;
}

.loginField {
  height: 100vh;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.loginPageTitle {
  font-size: 30px;
  font-weight: 600;
  color: #66c6b6;
  margin-bottom: 1em;
  margin-top: 0.5em;
}
